<template>
  <b-modal
    id="ask-copy-modal"
    title="Tạo lịch hẹn thành công, bạn có muốn copy link invitation ?"
    size="sm"
    hide-footer
    centered
  >
    <div class="d-flex justify-content-end align-items-center">
      <b-button class="btn mr-2" type="button" @click="onclickCloseAskCopy">
        Hủy
      </b-button>
      <b-button type="button" class="btn btn-success ml-3" @click="onClickCopy">
        Đồng ý
      </b-button>
    </div>
  </b-modal>
</template>
<script>
export default {
  name: 'AskCopyInvitationLink',
  props: {
    meetingLink: {
      type: [String, Number],
    },
    calendarId: {
      type: [String, String],
    },
    calendarName: {
      type: [String, String],
    },
    dynamicLink: {
      type: [String, String],
    }
  },
  methods: {
    onClickCopy() {
      //let $self = this.$api;

      // const longDynamicLink = `https://click.diab.com.vn/referralCode?link=https://diab.com.vn/calendar=${this.calendarId}&ofl=https://zoom.diab.com.vn?calendarId=${this.calendarId}&apn=com.vbhc.diab&ibi=com.cactusoftware.diab&isi=1569353448&sd=Ứng dụng hoàn toàn miễn phí giúp kiểm soát bệnh đái tháo đường và kết nối với chuyên gia&si=https://9s-booking.s3.ap-southeast-1.amazonaws.com/diab/linkzoom.jpg&st=${this.calendarName}`;
      // this.$api
      //   .getFirebaseDynamicLinks({
      //     longDynamicLink: longDynamicLink,
      //   })
      //   .then((dynamicLink) => {
      //     navigator.clipboard.writeText(dynamicLink);
      //     $self.put(`Calendar/UpdateDynamicLInk/${this.calendarId}`, {
      //       DynamicLink: dynamicLink
      //     }).then((res) => {
      //       console.log(res)
      //     })
      //   });
      navigator.clipboard.writeText(this.dynamicLink);
      this.$toastr.s({
        title: 'Thành công!',
        msg: 'Đã sao chép link',
      });
      this.$bvModal.hide('ask-copy-modal');
    },
    onclickCloseAskCopy() {
      // let $self = this.$api;
      // const longDynamicLink = `https://click.diab.com.vn/referralCode?link=https://diab.com.vn/calendar=${this.calendarId}&ofl=https://zoom.diab.com.vn?calendarId=${this.calendarId}&apn=com.vbhc.diab&ibi=com.cactusoftware.diab&isi=1569353448&sd=Ứng dụng hoàn toàn miễn phí giúp kiểm soát bệnh đái tháo đường và kết nối với chuyên gia&si=https://9s-booking.s3.ap-southeast-1.amazonaws.com/diab/linkzoom.jpg&st=${this.calendarName}`;
      // this.$api
      //   .getFirebaseDynamicLinks({
      //     longDynamicLink: longDynamicLink,
      //   })
      //   .then((dynamicLink) => {
      //     $self.put(`Calendar/UpdateDynamicLInk/${this.calendarId}`, {
      //       DynamicLink: dynamicLink
      //     }).then((res) => {
      //       console.log(res)
      //     })
      //   });

      this.$toastr.s({
        title: 'Thành công!',
        msg: 'Tạo mới lịch hẹn thành công',
      });
      this.$bvModal.hide('ask-copy-modal');
    },
  },
};
</script>
